<template>
  <b-button
    size="sm"
    class="mr-2"
    variant="primary"
    title="Controlar exibição do produto"
    @click="() => open()"
  >
    <b-icon icon="eye" />

    <b-modal
      v-model="isOpen"
      size="lg"
      title="Controle de exibição do produto"
      hide-footer
    >
      <div class="d-flex justify-content-center">
        <operation-button
          name="Exibição E-Commerce"
          icon="shop"
          :description="
            show
              ? 'Deixar de exibir produto no E-Commerce'
              : 'Exibir produto no E-Commerce'
          "
          :variant="show ? 'danger' : 'success'"
          @click="() => deactivate()"
        />

        <operation-button
          name="Exibição B2B"
          icon="building"
          :description="
            showB2b
              ? 'Deixar de exibir produto no B2B'
              : 'Exibir produto no B2B'
          "
          :variant="showB2b ? 'danger' : 'success'"
          @click="() => deactivateB2b()"
        />
      </div>
    </b-modal>
  </b-button>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OperationButton from "../button/OperationButton.vue";

export default {
  name: "ControlDisplay",
  components: { OperationButton },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    showB2b: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState("product", ["errors"]),
  },
  methods: {
    ...mapActions("product", ["hideProduct", "hideProductB2b"]),

    async open() {
      this.isOpen = !this.isOpen;
    },
    async deactivate() {
      const data = {
        id: this.productId,
        values: {
          show: !this.show,
        },
      };

      await this.hideProduct(data);

      this.isOpen = false;

      if (this.errors.length) {
        this.$emit("error", true);
      }
    },
    async deactivateB2b() {
      const data = {
        id: this.productId,
        values: {
          show: !this.showB2b,
        },
      };

      await this.hideProductB2b(data);

      this.isOpen = false;

      if (this.errors.length) {
        this.$emit("error", true);
      }
    },
  },
};
</script>
