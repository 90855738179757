<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Produtos</h4>
          <search-input
            v-model="searchProduct"
            :is-loading-search="isLoadingSearch"
            @enter="search"
          />
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            :items="products"
            :fields="fields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
            @row-clicked="changeRoute"
          >
            <template #cell(active)="row">
              {{ row.item.active ? "Ativo" : "Desativado" }}
            </template>

            <template #cell(image)="row">
              <img
                v-if="row.item.image[0]"
                :src="row.item.image[0].imgUrl"
                width="70"
              />
              <img v-else src="../../assets/not-found-image.png" width="70" />
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                title="Editar produto"
                @click="changeRoute(row.item)"
              >
                <b-icon icon="pencil" />
              </b-button>

              <ControlDisplay
                :product-id="row.item.id"
                :show="row.item.show"
                :showB2b="row.item.showB2b"
                @error="(value) => (showErrorModal = value)"
              />

              <a
                :href="`https://www.apeuloja.com.br/produto/${row.item.id}`"
                target="_blank"
                rel="noopener noreferrer"
                class="btn btn-info btn-sm"
                title="Acessar no site"
              >
                <b-icon-globe2 />
              </a>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>

        <b-modal
          ok-title="ENTENDI"
          ok-only
          v-model="showErrorModal"
          title="Os seguintes erros foram encontrados"
        >
          <ol>
            <li v-for="erro in errors" :key="erro">{{ erro }}</li>
          </ol>
        </b-modal>
      </b-col>
    </b-row>
    <router-view />
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import SearchInput from "../../components/shared/SearchInput.vue";
import ControlDisplay from "../../components/products/ControlDisplay.vue";

export default {
  components: { SearchInput, ControlDisplay },
  name: "productDetalis",
  data() {
    return {
      page: 2,
      searchProduct: "",
      modalMessage: "",
      showModal: false,
      showErrorModal: false,
      productToDeactvate: {},
      fields: [
        {
          key: "image",
          label: "Imagem",
          thStyle: { width: "70px !important" },
        },
        {
          key: "productCode",
          label: "Código",
          thStyle: { width: "90px !important" },
          sortable: true,
        },
        {
          key: "completeDescription",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "manufacturer",
          label: "Fabricante",
          sortable: true,
        },
        {
          key: "actions",
          thStyle: { width: "150px !important" },
          label: "Ações",
        },
      ],
    };
  },
  computed: {
    ...mapState("product", [
      "products",
      "isLoading",
      "isLoadingSearch",
      "errors",
    ]),
    ...mapGetters("auth", ["userPermission"]),

    hideButton() {
      return this.userPermission([1, 3]);
    },
  },
  methods: {
    ...mapActions("product", [
      "getAllProducts",
      "getOneProduct",
      "getMoreProducts",
      "searchProducts",
      "getMoreSearch",
      "hideProduct",
      "setErrorMessage",
    ]),
    ...mapMutations(["setOverlay"]),

    async changeRoute({ id }) {
      await this.getOneProduct(id);
      this.$router.push(`produtos/${id}/editar`);
    },
    async getMore() {
      const { page, searchProduct: search } = this;

      if (this.searchProduct) {
        await this.getMoreSearch({ page, search });
      } else {
        await this.getMoreProducts(page);
      }

      this.page = page + 1;
    },
    async search() {
      this.page = 2;
      if (this.searchProduct) {
        await this.searchProducts(this.searchProduct);
      } else {
        await this.getAllProducts();
      }
    },
  },
  async created() {
    if (!this.products.length) {
      this.setOverlay(true);
      await this.getAllProducts();
      this.setOverlay(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.apeu-search-input {
  width: 350px;
}
</style>
